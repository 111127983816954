<template>
  <svg
    fill="none"
    height="40"
    viewBox="0 0 50 40"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.5796 33.7273V35.1C41.5796 37.3743 39.7239 39.218 37.4347 39.218H4.2753C1.98609 39.218 0.130371 37.3743 0.130371 35.1V13.1372C0.130371 10.8629 1.98609 9.01923 4.2753 9.01923H5.65694V26.8639C5.65694 30.6484 8.75596 33.7273 12.5652 33.7273H41.5796ZM49.8695 26.8639V4.90122C49.8695 2.62687 48.0138 0.783203 45.7246 0.783203H12.5652C10.2759 0.783203 8.42023 2.62687 8.42023 4.90122V26.8639C8.42023 29.1383 10.2759 30.982 12.5652 30.982H45.7246C48.0138 30.982 49.8695 29.1383 49.8695 26.8639ZM22.2366 9.01923C22.2366 11.2936 20.3809 13.1372 18.0917 13.1372C15.8025 13.1372 13.9468 11.2936 13.9468 9.01923C13.9468 6.74488 15.8025 4.90122 18.0917 4.90122C20.3809 4.90122 22.2366 6.74488 22.2366 9.01923ZM13.9468 21.3733L18.7407 16.6105C19.1453 16.2085 19.8014 16.2085 20.2062 16.6105L23.6183 20.0006L35.3204 8.3745C35.725 7.97248 36.3811 7.97248 36.7859 8.3745L44.3429 15.8826V25.4913H13.9468V21.3733Z"
      fill="currentColor"
    />
  </svg>
</template>
